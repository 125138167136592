<template>
    <v-container fluid>
      <v-row style="margin-bottom: 100px">
        <v-col
          cols="12"
          xs="12" 
          sm="12" 
          md="4"
        >
          <v-card class="pa-md-3">   
            <div id="tenant" style="height: 370px; width: 100%;"></div>
            <p style="text-align: right;">{{ total_tenant }}</p>
          </v-card>
          <v-dialog v-model="dialog" max-width="1000px">                
            <v-card>
                <v-progress-linear
                        :active="loading"
                        :indeterminate="loading"
                        absolute
                        bottom
                        color="deep-purple accent-4"
                ></v-progress-linear>
                <v-card-title>Detail Tenant</v-card-title>
                <v-card-text>
                      <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-card-title>Tenant Item
                                    <v-spacer></v-spacer>
                                    <v-text-field
                                        v-model="$store.state.text"
                                        append-icon="mdi-magnify"
                                        label="Search..."
                                        single-line
                                        @keyup.enter="search"
                                        hide-details
                                    ></v-text-field>
                                </v-card-title>
                                <v-data-table
                                    :headers="header_items"
                                    :items="tenants"
                                    :options.sync="option_items"
                                    :loading="loading"
                                    loading-text="Please wait, retrieving data"
                                    :server-items-length="total_items"
                                    :page.sync="pagination"
                                    page-count="10"
                                    class="elevation-1"
                                >
                                  <!-- <template v-slot:item.actions="{ item }">
                                      <v-tooltip top>
                                          <template v-slot:activator="{ on }">
                                              <v-icon small class="mr-2" @click="editItemTenant(item)" v-on="on">
                                                  mdi-pencil
                                              </v-icon>
                                          </template>
                                          <span>Show</span>
                                      </v-tooltip>
                                  </template> -->
                                </v-data-table>
                                <!-- <v-dialog v-model="dialog_item_tenant" max-width="800px">                
                                  <v-card>
                                      <v-progress-linear
                                              :active="loading_item_tenant"
                                              :indeterminate="loading_item_tenant"
                                              absolute
                                              bottom
                                              color="deep-purple accent-4"
                                      ></v-progress-linear>
                                      <v-card-title>Item Tenant</v-card-title>
                                      <v-card-text>
                                          <v-container fluid>
                                            <v-row>
                                              <v-col cols="12">
                                                <input type="file" name="pdf_file" id="pdf_file" @change="setPDF" class="form-control" accept="pdf/*">
                                              </v-col>
                                            </v-row>
                                          </v-container>
                                      </v-card-text>
                                      <v-card-actions>
                                          <v-spacer></v-spacer>
                                          <v-btn
                                          class="mr-2"
                                          color="error"
                                          elevation="2"
                                          large
                                          rounded
                                          @click="saveFilePdf()"
                                          >Save</v-btn>
                                          <v-btn color="blue darken-1" text @click="close_item_tenant">Cancel</v-btn>
                                      </v-card-actions>
                                  </v-card>
                                </v-dialog> -->
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col
          cols="12"
          xs="12" 
          sm="12" 
          md="4"
          v-show="display == 'none' ? false: true"
        >
          <v-card class="pa-md-5">   
            <div id="other_payment" style="height: 370px; width: 100%;"></div>
            <p></p>
          </v-card>
          <v-dialog v-model="dialog_other_payment" max-width="1000px">                
            <v-card>
                <v-progress-linear
                        :active="loading_other_payment"
                        :indeterminate="loading_other_payment"
                        absolute
                        bottom
                        color="deep-purple accent-4"
                ></v-progress-linear>
                <v-card-title>Detail Other</v-card-title>
                <v-card-text>
                      <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-card-title>Tenant Item
                                    <v-spacer></v-spacer>
                                    <v-text-field
                                        v-model="$store.state.text"
                                        append-icon="mdi-magnify"
                                        label="Search..."
                                        single-line
                                        @keyup.enter="search_other_payment"
                                        hide-details
                                    ></v-text-field>
                                </v-card-title>
                                <v-data-table
                                    :headers="header_other_payment_items"
                                    :items="detail_other_payments"
                                    :options.sync="option_item_other_payments"
                                    :loading="loading_other_payment"
                                    loading-text="Please wait, retrieving data"
                                    :server-items-length="total_item_other_payment"
                                    :page.sync="pagination_other_payment"
                                    page-count="10"
                                    class="elevation-1"
                                >
                                  <template v-slot:[`item.amount`]="{ item }">
                                      {{ $store.getters.convertToCurrency(item.amount) }}
                                  </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close_other_payment">Cancel</v-btn>
                </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col
          cols="12"
          xs="12" 
          sm="12" 
          md="4"
          v-show="display == 'none' ? false: true"
        >
          <v-card class="pa-md-3">   
            <div id="piutang" style="height: 370px; width: 100%;"></div>
            <p style="text-align: right;color: #e50400;font-weight: 700;"> Rp {{ $store.getters.convertToCurrency(total_piutang) }}</p>

          </v-card>
          <v-dialog v-model="dialog_piutang" max-width="1000px">                
            <v-card>
                <v-progress-linear
                        :active="loading_piutang"
                        :indeterminate="loading_piutang"
                        absolute
                        bottom
                        color="deep-purple accent-4"
                ></v-progress-linear>
                <v-card-title>Detail Piutang</v-card-title>
                <v-card-text>
                      <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-card-title>Piutang Item
                                    <v-spacer></v-spacer>
                                    <v-text-field
                                        v-model="$store.state.text"
                                        append-icon="mdi-magnify"
                                        label="Search..."
                                        single-line
                                        @keyup.enter="search_piutang"
                                        hide-details
                                    ></v-text-field>
                                </v-card-title>
                                <v-data-table
                                    :headers="header_item_piutangs"
                                    :items="data_piutang"
                                    :options.sync="option_item_piutangs"
                                    :loading="loading_piutang"
                                    loading-text="Please wait, retrieving data"
                                    :server-items-length="total_item_piutangs"
                                    :page.sync="pagination_piutang"
                                    page-count="10"
                                    class="elevation-1"
                                >
                                  <template v-slot:[`item.amt_total`]="{ item }">
                                      {{ $store.getters.convertToCurrency(item.amt_total) }}
                                  </template>
                                  <template v-slot:[`item.amt_paid`]="{ item }">
                                      {{ $store.getters.convertToCurrency(item.amt_paid) }}
                                  </template>
                                  <template v-slot:[`item.amt_dp`]="{ item }">
                                      {{ $store.getters.convertToCurrency(item.amt_dp) }}
                                  </template>
                                  <template v-slot:[`item.amt_refund`]="{ item }">
                                      {{ $store.getters.convertToCurrency(item.amt_refund) }}
                                  </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close_piutang">Cancel</v-btn>
                </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col
          cols="12"
          xs="12" 
          sm="3" 
          md="3"
          v-show="display == 'none' ? false: true"
        >
          <v-card class="pa-md-4">   
            <div id="shop_summary_last_month" style="height: 370px; width: 100%;"></div>
            <p style="text-align: right;color: #e50400;font-weight: 700;"> Rp {{ $store.getters.convertToCurrency(total_shop_summary_last_month) }}</p>

          </v-card>
          <v-dialog v-model="dialog_shop_last" max-width="1000px">                
            <v-card>
                <v-progress-linear
                        :active="loading_shop_last_month"
                        :indeterminate="loading_shop_last_month"
                        absolute
                        bottom
                        color="deep-purple accent-4"
                ></v-progress-linear>
                <v-card-title>Detail Shop</v-card-title>
                <v-card-text>
                      <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-card-title>Shop Item
                                    <v-spacer></v-spacer>
                                    <v-text-field
                                        v-model="$store.state.text"
                                        append-icon="mdi-magnify"
                                        label="Search..."
                                        single-line
                                        @keyup.enter="search_shop"
                                        hide-details
                                    ></v-text-field>
                                </v-card-title>
                                <v-data-table
                                    :headers="header_item_shops"
                                    :items="detail_shop_summary_lastest"
                                    :options.sync="option_item_shops"
                                    :loading="loading_shop_last_month"
                                    loading-text="Please wait, retrieving data"
                                    :server-items-length="total_item_shops"
                                    :page.sync="pagination_shop"
                                    page-count="10"
                                    class="elevation-1"
                                >
                                  <template v-slot:[`item.unit_price`]="{ item }">
                                      {{ $store.getters.convertToCurrency(item.unit_price) }}
                                  </template>
                                  <template v-slot:[`item.amt_item`]="{ item }">
                                      {{ $store.getters.convertToCurrency(item.amt_item) }}
                                  </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close_shop">Cancel</v-btn>
                </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col
          cols="12"
          xs="12" 
          sm="3" 
          md="3"
          v-show="display == 'none' ? false: true"
        >
          <v-card class="pa-md-4">   
            <div id="shop_summary_this_month" style="height: 370px; width: 100%;"></div>
            <p style="text-align: right;color: #e50400;font-weight: 700;"> Rp {{ $store.getters.convertToCurrency(total_shop_summary_this_month) }}</p>

          </v-card>
          <v-dialog v-model="dialog_cost_by_dep" max-width="1000px">                
            <v-card>
                <v-progress-linear
                        :active="loading_cost_by_dep"
                        :indeterminate="loading_cost_by_dep"
                        absolute
                        bottom
                        color="deep-purple accent-4"
                ></v-progress-linear>
                <v-card-title>Cost ACC</v-card-title>
                <v-card-text>
                      <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-card-title>Cost ACC
                                </v-card-title>

                                <div id="cost_acc_by_dept" style="height: 370px; width: 100%;"></div>
                                <p style="text-align: right;color: #e50400;font-weight: 700;"> Rp {{ $store.getters.convertToCurrency(total_cost_acc_num) }}</p>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close_cost_acc">Cancel</v-btn>
                </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialog_acc_detail_last" max-width="1000px">                
            <v-card>
                <v-progress-linear
                        :active="loading_acc_last_month"
                        :indeterminate="loading_acc_last_month"
                        absolute
                        bottom
                        color="deep-purple accent-4"
                ></v-progress-linear>
                <v-card-title>Detail ACC Dept</v-card-title>
                <v-card-text>
                      <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-card-title>Detail ACC Dept
                                    <v-spacer></v-spacer>
                                    <v-text-field
                                        v-model="$store.state.text"
                                        append-icon="mdi-magnify"
                                        label="Search..."
                                        single-line
                                        @keyup.enter="search_acc_detail"
                                        hide-details
                                    ></v-text-field>
                                </v-card-title>
                                <v-data-table
                                    :headers="header_item_cost_acc"
                                    :items="detail_acc_summary_lastest"
                                    :options.sync="option_item_accs"
                                    :loading="loading_acc_last_month"
                                    loading-text="Please wait, retrieving data"
                                    :server-items-length="total_item_accs"
                                    :page.sync="pagination_acc"
                                    page-count="10"
                                    class="elevation-1"
                                >
                                  <template v-slot:[`item.dept_name`]="{ item }">
                                      {{ item.dept_name.trim() }}
                                  </template>
                                  <template v-slot:[`item.unit_price`]="{ item }">
                                      {{ $store.getters.convertToCurrency(item.unit_price) }}
                                  </template>
                                  <template v-slot:[`item.amt_item`]="{ item }">
                                      {{ $store.getters.convertToCurrency(item.amt_item) }}
                                  </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close_acc_detail">Cancel</v-btn>
                </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col
          cols="12"
          xs="12" 
          sm="3" 
          md="3"
          v-show="display == 'none' ? false: true"
        >
          <v-card class="pa-md-4">   
            <div id="cost_by_dept" style="height: 370px; width: 100%;"></div>
            <p style="text-align: right;color: #e50400;font-weight: 700;"> Rp {{ $store.getters.convertToCurrency(total_cost_by_dept) }}</p>

          </v-card>
        </v-col>
        <v-col
          cols="12"
          xs="12" 
          sm="3" 
          md="3"
          v-show="display == 'none' ? false: true"
        >
          <v-card class="pa-md-4">   
            <div id="cost_by_dept_last_month" style="height: 370px; width: 100%;"></div>
            <p style="text-align: right;color: #e50400;font-weight: 700;"> Rp {{ $store.getters.convertToCurrency(total_cost_by_dept_last_month) }}</p>

          </v-card>
        </v-col>
        <v-col
          cols="12"
          xs="12" 
          sm="12" 
          md="12"
          v-show="display == 'none' ? false: true"
        >
          <v-card class="pa-md-4">   
            <div id="payment" style="height: 400px; width: 100%;"></div>
          </v-card>
          <v-dialog v-model="dialog_payment" max-width="1000px">                
            <v-card>
                <v-progress-linear
                        :active="loading_payment"
                        :indeterminate="loading_payment"
                        absolute
                        bottom
                        color="deep-purple accent-4"
                ></v-progress-linear>
                <v-card-title>Detail Payment</v-card-title>
                <v-card-text>
                      <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-card-title>Payment Item
                                    <v-spacer></v-spacer>
                                    <v-text-field
                                        v-model="$store.state.text"
                                        append-icon="mdi-magnify"
                                        label="Search..."
                                        single-line
                                        @keyup.enter="search_payment"
                                        hide-details
                                    ></v-text-field>
                                </v-card-title>
                                <v-data-table
                                    :headers="header_item_payments"
                                    :items="data_item_payments"
                                    :options.sync="option_item_payments"
                                    :loading="loading_payment"
                                    loading-text="Please wait, retrieving data"
                                    :server-items-length="total_item_payments"
                                    :page.sync="pagination_payment"
                                    page-count="10"
                                    class="elevation-1"
                                >
                                  <template v-slot:[`item.inv_amt`]="{ item }">
                                      {{ $store.getters.convertToCurrency(item.inv_amt) }}
                                  </template>
                                  <template v-slot:[`item.pay_amt`]="{ item }">
                                      {{ $store.getters.convertToCurrency(item.pay_amt) }}
                                  </template>
                                  <template v-slot:[`item.owing_amt`]="{ item }">
                                      {{ $store.getters.convertToCurrency(item.owing_amt) }}
                                  </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close_payment">Cancel</v-btn>
                </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialog_inv_payment" max-width="1000px">                
            <v-card>
                <v-progress-linear
                        :active="loading_inv_payment"
                        :indeterminate="loading_inv_payment"
                        absolute
                        bottom
                        color="deep-purple accent-4"
                ></v-progress-linear>
                <v-card-title>Detail Invoice Payment</v-card-title>
                <v-card-text>
                      <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-card-title>Invoice Payment Item
                                    <v-spacer></v-spacer>
                                    <v-text-field
                                        v-model="$store.state.text"
                                        append-icon="mdi-magnify"
                                        label="Search..."
                                        single-line
                                        @keyup.enter="search_inv_payment"
                                        hide-details
                                    ></v-text-field>
                                </v-card-title>
                                <v-data-table
                                    :headers="header_item_inv_payments"
                                    :items="data_item_inv_payments"
                                    :options.sync="option_item_inv_payments"
                                    :loading="loading_inv_payment"
                                    loading-text="Please wait, retrieving data"
                                    :server-items-length="total_item_inv_payments"
                                    :page.sync="pagination_inv_payment"
                                    page-count="10"
                                    class="elevation-1"
                                >
                                  <template v-slot:[`item.amt_total`]="{ item }">
                                      {{ $store.getters.convertToCurrency(item.amt_total) }}
                                  </template>
                                  <template v-slot:[`item.amt_paid`]="{ item }">
                                      {{ $store.getters.convertToCurrency(item.amt_paid) }}
                                  </template>
                                  <template v-slot:[`item.amt_dp`]="{ item }">
                                      {{ $store.getters.convertToCurrency(item.amt_dp) }}
                                  </template>
                                  <template v-slot:[`item.amt_refund`]="{ item }">
                                      {{ $store.getters.convertToCurrency(item.amt_refund) }}
                                  </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close_inv_payment">Cancel</v-btn>
                </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-container>
</template>
<script>
  import Chart from 'chart.js/auto';
  
  export default {
    data() {
        return {
          display: 'none',
          dashboard: {},
          dialog: false,
          dialog_piutang: false,
          dialog_payment: false,
          search_detail: '',
          header_items:[
            {
                text: 'Tenant ID',
                align: 'start',
                sortable: true,
                value: 'tenant_id',
            },
            { text: 'Trans Date', value: 'dt_trans' },
            { text: 'Name', value: 'tenant_name' },
            { text: 'Rent ID', value: 'rent_id' }
            // { text: 'Actions',  value: 'actions', sortable: false }
          ],
          header_item_piutangs:[
            {
                text: 'Nama',
                align: 'start',
                sortable: true,
                value: 'nama',
            },
            { text: 'Tenant Name', value: 'tenant_name' },
            { text: 'Amt Total', value: 'amt_total' },
            { text: 'Amt Paid', value: 'amt_paid' },
            { text: 'Amt DP', value: 'amt_dp' },
            { text: 'Amt Refund', value: 'amt_refund' },
            { text: 'Status', value: 'stat' }
          ],
          header_item_payments:[
            {
                text: 'Date Pay',
                align: 'start',
                sortable: true,
                value: 'dt_pay',
            },
            { text: 'Customer', value: 'customer' },
            { text: 'Tenant Name', value: 'tenant_name' },
            { text: 'Inv ID', value: 'inv_id' },
            { text: 'Inv Amount', value: 'inv_amt' },
            { text: 'Pay Amount', value: 'pay_amt' },
            { text: 'Owing Amount', value: 'owing_amt' }
          ],
          header_item_shops:[
            {
                text: 'Date Inv',
                align: 'start',
                sortable: true,
                value: 'dt_inv',
            },
            { text: 'POS Belanja', value: 'pos_belanja' },
            { text: 'Inv ID', value: 'inv_id' },
            { text: 'Description', value: 'description' },
            { text: 'Unit Price', value: 'unit_price' },
            { text: 'Qty', value: 'qty' },
            { text: 'Amount Item', value: 'amt_item' }
          ],
          header_item_cost_acc:[
            {
                text: 'Date TR',
                align: 'start',
                sortable: true,
                value: 'tr_date',
            },
            { text: 'TR ID', value: 'tr_id' },
            { text: 'Department', value: 'dept_name' },
            { text: 'Name', value: 'ac_name' },
            { text: 'Description', value: 'description' },
            { text: 'Unit Price', value: 'unit_price' },
            { text: 'Qty', value: 'qty' },
            { text: 'Amount Item', value: 'amt_item' }
          ],
          header_item_inv_payments:[
            {
                text: 'Period',
                align: 'start',
                sortable: true,
                value: 'period',
            },
            { text: 'Cust ID', value: 'cust_id' },
            { text: 'Nama', value: 'nama' },
            { text: 'Tenant', value: 'tenant_name' },
            { text: 'Amt Total', value: 'amt_total' },
            { text: 'Amt Paid', value: 'amt_paid' },
            { text: 'Amt DP', value: 'amt_dp' },
            { text: 'Amt Refund', value: 'amt_refund' },
            { text: 'Status', value: 'stat' }
          ],
          header_other_payment_items:[
            {
                text: 'Period',
                align: 'start',
                sortable: true,
                value: 'period',
            },
            { text: 'TR Type', value: 'tr_type' },
            { text: 'Item Notes', value: 'item_notes' },
            { text: 'Amount', value: 'amount' }
          ],
          tenants: [],
          option_items: {},
          option_item_piutangs: {},
          option_item_payments: {},
          option_item_shops: {},
          option_item_accs: {},
          option_item_inv_payments: {},
          option_item_other_payments: {},
          loading: false,
          loading_piutang: false,
          loading_payment: false,
          loading_inv_payment: false,
          total_items: 10, 
          total_item_piutangs: 10,
          total_item_payments: 10,
          total_item_shops: 10,
          total_item_accs: 10,
          rent_id: '', 
          rent_temp: '', 
          pagination: 1,
          pagination_piutang: 1,
          pagination_payment: 1,
          pagination_shop: 1,
          pagination_acc: 1,
          data_tenant: [],
          dashboardPiutang: {},
          nama_piutang: '', 
          data_piutang: [],
          data_item_payments: [],
          total_tenant: 0,
          total_piutang: 0,
          total_other_payment: 0,
          data_payments: [],
          period: '',
          item_tenant: '',
          loading_item_tenant: false,
          dialog_item_tenant: false,
          file_src: '',
          file_name: '',
          total_shop_summary_last_month: 0,
          shop_summary_lastest: [],
          detail_shop_summary_lastest: [],
          detail_acc_summary_lastest: [],
          loading_shop_last_month: false,
          loading_acc_last_month: false,
          dialog_shop_last_month: false,
          total_shop_summary_this_month: 0,
          shop_summary_recents: [],
          detail_shop_summary_recents: [],
          loading_shop_last: false,
          dialog_shop_last: false,
          dialog_acc_detail_last: false,
          pos_shop: '',
          last_month: null,
          total_cost_by_dept_last_month: 0,
          total_cost_by_dept: 0,
          data_chart_cost_dept_last_month: [],
          data_chart_cost_dept: [],
          dialog_cost_by_dep: false,
          loading_cost_by_dep: false,
          total_cost_acc_by_dept: 0,
          total_cost_acc_num: 0,
          department_last_month: '',
          department: '',
          period_last_month: '',
          periode_last_month: '',
          period_this_month: '',
          periode_this_month: '',
          period: '',
          nama_acc: '',
          group_user: '',
          period_inv: '',
          dialog_inv_payment: false,
          pagination_inv_payment: 1,
          data_item_inv_payments: [],
          total_item_inv_payments: 10,
          period_other_payment: '',
          data_other_payments: {},
          tr_type_other_payment: '',
          dialog_other_payment: false,
          pagination_other_payment: 1,
          loading_other_payment: false,
          detail_other_payments: [],
          total_item_other_payment: 10
        }
    },
    async mounted(){
        await axios.get(`${process.env.VUE_APP_URL}/api/get_group_user?entity_id=SRMALL&appl_id=APPROVAL`, { 
            headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
        })
        .then(res => {
          this.group_user = res.data.data

          if (this.group_user === 'DEVELOPMENT' || this.group_user === 'MANAGER SR MALL') {
            this.display = 'block'
          }
        });

        await axios.get(`${process.env.VUE_APP_URL}/api/sr_mall`, { 
          headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
        })
        .then(res => {
          this.dashboard = res.data.data
          this.total_tenant = res.data.total

          this.renderChartDoghnut(this.dashboard)

        });

        await axios.get(`${process.env.VUE_APP_URL}/api/sr_mall/piutang`, { 
              headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
        })
        .then(res => {
          this.dashboardPiutang = res.data.data
          this.total_piutang = res.data.total

          this.renderChartDoghnutPiutang(this.dashboardPiutang)

        });

        await axios.get(`${process.env.VUE_APP_URL}/api/sr_mall/payment`, { 
          headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
        })
        .then(res => {

          this.data_payments = res.data

          this.renderChartBarPayment(this.data_payments)

        });

        await axios.get(`${process.env.VUE_APP_URL}/api/sr_mall/other_payment`, { 
          headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
        })
        .then(res => {

          this.data_other_payments = res.data
          this.total_other_payment = res.data.total
          this.period_other_payment = res.data.period

          this.renderChartBarOtherPayment(this.data_other_payments)

        });

        await axios.get(`${process.env.VUE_APP_URL}/api/sr_mall/shop?last_month=1`, { 
          headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
        })
        .then(res => {
          this.shop_summary_lastest = res.data ? res.data.data : []
          this.total_shop_summary_last_month = res.data ? res.data.total : 0

          this.renderChartShopLatest(this.shop_summary_lastest)

        });

        await axios.get(`${process.env.VUE_APP_URL}/api/sr_mall/shop?last_month=0`, { 
          headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
        })
        .then(res => {
          this.shop_summary_recents = res.data ? res.data.data : []
          this.total_shop_summary_this_month = res.data ? res.data.total : 0

          this.renderChartShop(this.shop_summary_recents)

        });

        await axios.get(`${process.env.VUE_APP_URL}/api/sr_mall/cost_dept?last_month=1`, { 
          headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
        })
        .then(res => {
          this.data_chart_cost_dept_last_month = res.data.data
          this.total_cost_by_dept_last_month = res.data ? res.data.total : 0
          this.period_last_month = res.data.period
          this.periode_last_month = res.data.periode

          this.renderChartCostDeptLatest(this.data_chart_cost_dept_last_month)

        });

        await axios.get(`${process.env.VUE_APP_URL}/api/sr_mall/cost_dept?last_month=0`, { 
              headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
        })
        .then(res => {
          this.data_chart_cost_dept = res.data.data
          this.total_cost_by_dept = res.data ? res.data.total : 0
          this.period_this_month = res.data.period
          this.periode_this_month = res.data.periode

          this.renderChartCostDept(this.data_chart_cost_dept)

        });
        
        window.onpopstate = (e) => {
          this.close()
          this.close_piutang()
          this.close_payment()
          this.close_item_tenant()
          this.close_shop()
          this.close_cost_acc()
          this.close_acc_detail()
          this.close_inv_payment()     
          this.close_other_payment()     
        };
    },
    methods:{
        close() {
            this.dialog = false
        },
        close_piutang(){
            this.dialog_piutang = false
        },
        close_payment(){
          this.dialog_payment = false
        },
        close_item_tenant(){
          this.dialog_item_tenant = false
        },
        close_shop(){
          this.dialog_shop_last = false
        },
        close_cost_acc(){
          this.dialog_cost_by_dep = false
        },
        close_acc_detail(){
          this.dialog_acc_detail_last = false
        },
        close_inv_payment(){
          this.dialog_inv_payment = false
        },
        close_other_payment(){
          this.dialog_other_payment = false
        },
        search(){
          this.getItemTenant(1, 10, this.rent_id)
        },
        search_piutang(){
          this.getItemPiutang(1, 10, this.nama_piutang)
        },
        search_payment(){
          this.getItemPayment(1, 10, this.period) 
        },
        search_shop(){
          this.getItemPayment(1, 10, this.period) 
        },
        search_acc_detail(){
          this.getItemAccbyDept(1,10, this.period_last_month)
        },
        search_inv_payment(){
          this.getItemInvPayment(1,10, this.period_inv)
        },
        search_other_payment(){
          this.getItemOtherPayment(1, 10, this.period_other_payment)
        },
        renderChartDoghnut(data){

          this.data_tenant = data
          var chart = new CanvasJS.Chart("tenant", {
                        animationEnabled: true,
                        title: {
                          text: "Tenant"
                        },
                        data: [{
                          type: "pie",
                          click: this.onClick,
                          startAngle: 240,
                          yValueFormatString: "##0.00\"%\"",
                          indexLabel: "{label} {y}",
                          dataPoints: this.data_tenant
                        }]
                      });
          chart.render();
          // let ctx = document.getElementById("tenant")
            
          //   return new Chart(ctx, {
          //     type: 'doughnut',
          //     data: {
          //       datasets: [{
          //         data: data.total,
          //         backgroundColor: [
          //           'rgb(255, 205, 86)',
          //           'rgb(255, 99, 132)',
          //           'rgb(75, 192, 192)',
          //           '#1565C0'
          //         ]
          //       }],
          //       labels: data.rent_id,
          //     },
          //     options: {
          //       responsive: true,
          //       legend: {
          //         position: 'top',
          //       },
          //       title: {
          //         display: true,
          //         text: 'Pesanan'
          //       },
          //       animation: {
          //         animateScale: true,
          //         animateRotate: true
          //       },
          //       onClick: (e, activeEls) => {
          //         let datasetIndex = activeEls[0].datasetIndex;
          //         let dataIndex = activeEls[0].index;
          //         let value = e.chart.data.datasets[datasetIndex].data[dataIndex];
          //         let label = e.chart.data.labels[dataIndex];

          //         this.rent_id = label
          //         this.dialog = true
          //         this.pagination = 1
          //         this.getItemTenant(label)
          //       }
          //     }
          //   })
        },
        renderChartDoghnutPiutang(data){
          var chart2 = new CanvasJS.Chart("piutang", {
                        animationEnabled: true,
                        title: {
                          text: "Piutang Summary"
                        },
                        data: [{
                          type: "pie",
                          click: this.onClickPiutang,
                          startAngle: 240,
                          yValueFormatString: "##0.00\"%\"",
                          indexLabel: "{label} {y}",
                          dataPoints: this.dashboardPiutang
                        }]
                      });
          chart2.render();
        },
        renderChartBarPayment(datas){
          var chart3 = new CanvasJS.Chart("payment", {
                        animationEnabled: true,
                        title: {
                          text: "Invoice & Payment Summary"
                        },
                        axisX:{
                          title : "(Year.Month)"
                        },
                        axisY:{
                          title : "(Million)"
                        },
                        data: [{
                          type: "column",
                          name: "Payment Summary",
                          click: this.onClickPayment,
                          startAngle: 240,
                          yValueFormatString: "##0.00\" Million\"",
                          indexLabel: "{y}",
                          dataPoints: datas.payment
                        },
                        {
                          type: "column",
                          name: "Invoice Summary",
                          click: this.onClickInvPayment,
                          startAngle: 240,
                          yValueFormatString: "##0.00\" Million\"",
                          indexLabel: "{y}",
                          dataPoints: datas.piutang
                        }]
                      });
          chart3.render();
        },
        renderChartBarOtherPayment(datas){
          var chart3 = new CanvasJS.Chart("other_payment", {
                        animationEnabled: true,
                        title: {
                          text: "Other Payment & Deposit This Month"
                        },
                        axisY: {
                          labelFormatter: function (e) {
                            return CanvasJS.formatNumber(e.value, "#,#00");
                          },
                          includeZero: false
                        },
                        data: [{
                          type: "column",
                          click: this.onClickOtherPayment,
                          startAngle: 240,
                          showInLegend: true,
                          legendText: "Other Deposit",
                          indexLabel: "{y}",
                          dataPoints: this.data_other_payments.data_od
                        },
                        {
                          type: "column",
                          click: this.onClickOtherPayment,
                          startAngle: 240,
                          showInLegend: true,
                          legendText: "Other Payment",
                          indexLabel: "{y}",
                          dataPoints: this.data_other_payments.data_op
                        }]
                      });
          chart3.render();
        },
        renderChartShopLatest(data){

          var chart4 = new CanvasJS.Chart("shop_summary_last_month", {
                        animationEnabled: true,
                        title: {
                          text: "Purchase Summary Last Month"
                        },
                        data: [{
                          type: "pie",
                          click: this.onClickShopLatest,
                          startAngle: 240,
                          yValueFormatString: "##0.00\"%\"",
                          indexLabel: "{label} {y}",
                          dataPoints: this.shop_summary_lastest
                        }]
                      });
          chart4.render();
        },
        renderChartShop(data){
          var chart5 = new CanvasJS.Chart("shop_summary_this_month", {
                        animationEnabled: true,
                        title: {
                          text: "Purchase Summary This Month"
                        },
                        data: [{
                          type: "pie",
                          click: this.onClickShop,
                          startAngle: 240,
                          yValueFormatString: "##0.00\"%\"",
                          indexLabel: "{label} {y}",
                          dataPoints: this.shop_summary_recents
                        }]
                      });
          chart5.render();
        },
        renderChartCostDeptLatest(data){
          var chart6 = new CanvasJS.Chart("cost_by_dept_last_month", {
                        animationEnabled: true,
                        title: {
                          text: "Cost by Dept Summary Last Month"
                        },
                        data: [{
                          type: "pie",
                          click: this.onCostDeptLatest,
                          startAngle: 240,
                          yValueFormatString: "##0.00\"%\"",
                          indexLabel: "{label} {y}",
                          dataPoints: this.data_chart_cost_dept_last_month
                        }]
                      });
          chart6.render();
        },
        renderChartCostDept(data){
          var chart6 = new CanvasJS.Chart("cost_by_dept", {
                        animationEnabled: true,
                        title: {
                          text: "Cost by Dept Summary This Month"
                        },
                        data: [{
                          type: "pie",
                          click: this.onCostDept,
                          startAngle: 240,
                          yValueFormatString: "##0.00\"%\"",
                          indexLabel: "{label} {y}",
                          dataPoints: this.data_chart_cost_dept
                        }]
                      });
          chart6.render();
        },
        onClick(e){
          this.rent_id = e.dataPoint.label
          this.dialog = true
          this.pagination = 1
          this.getItemTenant(1,10,e.dataPoint.label)
        },
        getItemTenant(page = 1, itemsPerPage = 10, item){
            this.loading = true

            axios.get(`${process.env.VUE_APP_URL}/api/sr_mall/tenant?search=${this.$store.state.text}&rent_id=${this.rent_id ? this.rent_id : ""}&page=${page > 1 ? page : 1}&pagination=${itemsPerPage}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.loading = false
                this.tenants = res.data.data
                this.total_items = res.data.total
                this.pagination = res.data.current_page
            })
        },
        onClickPiutang(e){
          this.nama_piutang = e.dataPoint.label
          this.dialog_piutang = true
          this.pagination_piutang = 1
          this.getItemPiutang(1,10,e.dataPoint.label)
        },
        getItemPiutang(page = 1, itemsPerPage = 10, item){
            this.loading_piutang = true

            axios.get(`${process.env.VUE_APP_URL}/api/sr_mall/tenant/piutang?search=${this.$store.state.text}&nama=${this.nama_piutang ? this.nama_piutang : ""}&page=${page > 1 ? page : 1}&pagination=${itemsPerPage}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.loading_piutang = false
                this.data_piutang = res.data.data
                this.total_item_piutangs = res.data.total
                this.pagination_piutang = res.data.current_page
            })
        },
        onClickPayment(e){
          this.period = e.dataPoint.label
          this.dialog_payment = true
          this.pagination_payment = 1
          this.getItemPayment(1,10,e.dataPoint.label)
        },
        onClickInvPayment(e){
          this.period_inv = e.dataPoint.label
          this.dialog_inv_payment = true
          this.pagination_inv_payment = 1
          this.getItemInvPayment(1,10,e.dataPoint.label)
        },
        getItemPayment(page = 1, itemsPerPage = 10, item){
            this.loading_payment = true

            axios.get(`${process.env.VUE_APP_URL}/api/sr_mall/tenant/payment?search=${this.$store.state.text}&period=${this.period ? this.period : ""}&page=${page > 1 ? page : 1}&pagination=${itemsPerPage}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.loading_payment = false
                this.data_item_payments = res.data.data
                this.total_item_payments = res.data.total
                this.pagination_payment = res.data.current_page
            })
        },
        getItemInvPayment(page = 1, itemsPerPage = 10, item){
          this.loading_inv_payment = true
          axios.get(`${process.env.VUE_APP_URL}/api/sr_mall/tenant/payment-piutang?search=${this.$store.state.text}&period=${this.period_inv ? this.period_inv : ""}&page=${page > 1 ? page : 1}&pagination=${itemsPerPage}`, { 
              headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
          })
          .then(res => {
              this.loading_inv_payment = false
              this.data_item_inv_payments = res.data.data
              this.total_item_inv_payments = res.data.total
              this.pagination_inv_payment = res.data.current_page
          })
        },
        editItemTenant(data){
          this.item_tenant = data
          this.dialog_item_tenant = true
        },
        onClickShop(e){
          this.pos_shop = e.dataPoint.ac_num
          this.dialog_shop_last = true
          this.pagination_shop = 1
          this.last_month = 0
          this.getItemShop(1, 10, this.last_month)
        },
        onClickShopLatest(e){
          this.pos_shop = e.dataPoint.ac_num
          this.dialog_shop_last = true
          this.pagination_shop = 1
          this.last_month = 1
          this.getItemShop(1, 10, this.last_month)
        },
        onCostDeptLatest(e){
          this.nama_acc = e.dataPoint.dept_id.trim()
          this.dialog_acc_detail_last = true
          this.pagination_acc = 1
          this.periode_this_month = ''
          this.getItemAccbyDept(1, 10, this.periode_last_month)
        },
        onCostDept(e){
          this.nama_acc = e.dataPoint.dept_id.trim()
          console.log(this.nama_acc);
          this.dialog_acc_detail_last = true
          this.pagination_acc = 1
          this.periode_last_month = ''

          this.getItemAccbyDeptthisMonth(1, 10, this.periode_this_month)
        },
        onClickOtherPayment(e){
          this.tr_type_other_payment = e.dataPoint.tr_type
          this.dialog_other_payment = true
          this.pagination_other_payment = 1
          this.getItemOtherPayment(1, 10, this.period_other_payment)
        },
        getItemOtherPayment(page = 1, itemsPerPage = 10, period){
          this.loading_other_payment = true

          axios.get(`${process.env.VUE_APP_URL}/api/sr_mall/tenant/other_payment?search=${this.$store.state.text}&period=${period}&tr_type=${this.tr_type_other_payment ? this.tr_type_other_payment : ''}&page=${page > 1 ? page : 1}&pagination=${itemsPerPage}`, { 
              headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
          })
          .then(res => {
              this.loading_other_payment = false
              this.detail_other_payments = res.data.data
              this.total_item_other_payment = res.data.total
              this.pagination_other_payment = res.data.current_page
          })
        },
        getCostDept(dept_name, period){
          axios.get(`${process.env.VUE_APP_URL}/api/sr_mall/cost_dept/${period}?dept_name=${dept_name}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
          })
          .then(res => {
            this.loading_cost_by_dep = false
            this.department_last_month = res.data.dept_name
            this.total_cost_acc_num = res.data.total
            console.log(res.data);

            this.renderChartCostAcc(res.data.data)


          });
        },
        getCostDeptLatest(dept_name, period){
          axios.get(`${process.env.VUE_APP_URL}/api/sr_mall/cost_dept/${period}?dept_name=${dept_name}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
          })
          .then(res => {
            this.loading_cost_by_dep = false
            this.department_last_month = res.data.dept_name
            this.total_cost_acc_num = res.data.total
            console.log(res.data);

            this.renderChartCostAcccLatest(res.data.data)


          });
        },
        renderChartCostAcccLatest(dataAcc){
          var chart7 = new CanvasJS.Chart("cost_acc_by_dept", {
                        animationEnabled: true,
                        title: {
                          text: "Cost ACC by Dept"
                        },
                        data: [{
                          type: "pie",
                          click: this.onCostDeptDtLatest,
                          startAngle: 240,
                          yValueFormatString: "##0.00\"%\"",
                          indexLabel: "{label} {y}",
                          dataPoints: dataAcc
                        }]
                      });
          chart7.render();
        },
        renderChartCostAcc(dataAcc){
          var chart7 = new CanvasJS.Chart("cost_acc_by_dept", {
                        animationEnabled: true,
                        title: {
                          text: "Cost ACC by Dept"
                        },
                        data: [{
                          type: "pie",
                          click: this.onCostDeptDt,
                          startAngle: 240,
                          yValueFormatString: "##0.00\"%\"",
                          indexLabel: "{label} {y}",
                          dataPoints: dataAcc
                        }]
                      });
          chart7.render();
        },
        getItemShop(page = 1, itemsPerPage = 10, item){
          this.loading_shop_last_month = true

          axios.get(`${process.env.VUE_APP_URL}/api/sr_mall/tenant/shop?last_month=${item}&search=${this.$store.state.text}&pos_belanja=${this.pos_shop ? this.pos_shop : ""}&page=${page > 1 ? page : 1}&pagination=${itemsPerPage}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.loading_shop_last_month = false
                this.detail_shop_summary_lastest = res.data.data
                this.total_item_shops = res.data.total
                this.pagination_shop = res.data.current_page
            })

        },
        onCostDeptDt(e){
          this.nama_acc = e.dataPoint.label
          this.dialog_acc_detail_last = true
          this.pagination_acc = 1
          this.getItemAccbyDeptthisMonth(1, 10, this.periode_this_month)
        },
        onCostDeptDtLatest(e){
          this.nama_acc = e.dataPoint.label
          this.dialog_acc_detail_last = true
          this.pagination_acc = 1
          this.getItemAccbyDept(1, 10, this.periode_last_month)
        },
        getItemAccbyDept(page = 1, itemsPerPage = 10, period){
          this.loading_acc_last_month = true

          axios.get(`${process.env.VUE_APP_URL}/api/sr_mall/tenant/cost_dept/${this.periode_last_month}?search=${this.$store.state.text}&dept_name=${this.nama_acc ? this.nama_acc : ""}&page=${page > 1 ? page : 1}&pagination=${itemsPerPage}`, { 
              headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
          })
          .then(res => {
              this.loading_acc_last_month = false
              this.detail_acc_summary_lastest = res.data.data
              this.total_item_accs = res.data.total
              this.pagination_acc = res.data.current_page
          })
        },
        getItemAccbyDeptthisMonth(page = 1, itemsPerPage = 10, period){
          this.loading_acc_last_month = true

          axios.get(`${process.env.VUE_APP_URL}/api/sr_mall/tenant/cost_dept/${this.periode_this_month}?search=${this.$store.state.text}&dept_name=${this.nama_acc ? this.nama_acc : ""}&page=${page > 1 ? page : 1}&pagination=${itemsPerPage}`, { 
              headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
          })
          .then(res => {
              this.loading_acc_last_month = false
              this.detail_acc_summary_lastest = res.data.data
              this.total_item_accs = res.data.total
              this.pagination_acc = res.data.current_page
          })
        },
        setPDF(e){
          const file = e.target.files[0]
          this.file_name = file.name
          if (typeof FileReader === 'function') {
            const reader = new FileReader()
            reader.onload = (event) => {
              this.file_src = event.target.result
            };
            reader.readAsDataURL(file)
          } else {
            alert('Sorry, FileReader API not supported')
          }
        },
        saveFilePdf(){
            this.loading_item_tenant = true

            axios.post(`${process.env.VUE_APP_URL}/api/upload_pdf`, {
                'name_file': this.file_name,
                'file_pdf': this.file_src
            }, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            }).then(res => {
                this.loading_item_tenant = false
                this.dialog_item_tenant = false
                this.file_src = ''
            }).catch(err => {
                this.loading_item_tenant = false
            })
        }
    },
    watch: {
        option_items: {
            handler () {
                this.loading = true
                const { page, itemsPerPage } = this.option_items
                if (this.rent_id) {
                    this.getItemTenant(page, itemsPerPage, this.rent_id)
                }
            },
            deep: true,
        },
        option_item_piutangs:{
          handler () {
                this.loading_piutang = true
                const { page, itemsPerPage } = this.option_item_piutangs
                if (this.nama_piutang) {
                    this.getItemPiutang(page, itemsPerPage, this.nama_piutang)
                }
            },
            deep: true,
        },
        option_item_payments:{
          handler () {
                this.loading_payment = true
                const { page, itemsPerPage } = this.option_item_payments
                if (this.period) {
                    this.getItemPayment(page, itemsPerPage, this.period)
                }
            },
            deep: true,
        },
        option_item_shops:{
          handler () {
                this.loading_shop_last_month = true
                const { page, itemsPerPage } = this.option_item_shops
                if (this.period) {
                    this.getItemShop(page, itemsPerPage, this.period)
                }
            },
            deep: true,
        },
        option_item_accs:{
          handler () {
                this.loading_acc_last_month = true
                const { page, itemsPerPage } = this.option_item_accs
                if (this.periode_last_month != '') {
                    this.getItemAccbyDept(page, itemsPerPage, this.periode_last_month)
                }
                if (this.periode_this_month != '') {
                  this.getItemAccbyDeptthisMonth(page, itemsPerPage, this.periode_this_month)
                }
            },
            deep: true,
        },
        option_item_inv_payments:{
          handler () {
                this.loading_inv_payment = true
                const { page, itemsPerPage } = this.option_item_inv_payments
                if (this.period_inv) {
                    this.getItemInvPayment(page, itemsPerPage, this.period_inv)
                }
            },
            deep: true,
        },
        option_item_other_payments:{
          handler () {
              this.loading_other_payment = true
              const { page, itemsPerPage } = this.option_item_other_payments
              if (this.period_other_payment) {
                  this.getItemOtherPayment(page, itemsPerPage, this.period_other_payment)
              }
          },
          deep: true,
        }
    }
  }
</script>